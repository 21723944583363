<template>
  <v-card>
    <v-card-title
      v-if="$vuetify.breakpoint.smAndUp"
      class="align-start"
    >
      <v-avatar
        :color="color"
        size="60"
        class="elevation-3"
      >
        <v-icon
          size="35"
          color="white"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary">
      <p
        class="font-weight-semibold mb-1"
        :class="{
          'text-xs': $vuetify.breakpoint.xsOnly,
          'text-sm': $vuetify.breakpoint.smAndUp,
        }"
      >
        {{ statTitle }}
        <span
          v-if="change !== '0' && $vuetify.breakpoint.xsOnly"
          class="percentage text-xs"
          :class="checkChange(change) ? 'success--text' : 'error--text'"
        > {{ change }}%</span>
      </p>

      <div class="d-flex align-end flex-wrap">
        <span
          class="font-weight-semibold me-1 mb-2"
          :class="{
            'text-2xl': $vuetify.breakpoint.mdAndDown,
            'text-4xl': $vuetify.breakpoint.lgAndUp,
          }"
        >
          {{ statistics }}
        </span>
        <span
          v-if="change !== '0' && $vuetify.breakpoint.smAndUp"
          class="percentage mb-2"
          :class="checkChange(change) ? 'success--text':'error--text'"
        > {{ change }}%</span>
      </div>
      <p class="text-xs text--secondary mb-0 text-wrap">
        {{ subtitle }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    statistics: { type: [String, Number], default: '' },
    change: { type: String, default: '' },
  },
  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return {
      mdiDotsVertical,
      checkChange,
    }
  },
}
</script>

<style lang="scss" scoped>
.percentage {
  top: -7px;
  position: relative;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    top: 0px;
    font-size: 9px !important;
  }
}
</style>
