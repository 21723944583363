<template>
  <div>
    <v-row
      v-if="analyticsData"
      class="mb-5"
    >
      <v-col
        cols="6"
        md="3"
      >
        <statistics-card-vertical
          color="primary"
          :icon="icons.mdiCurrencyUsd"
          :statistics="`$${analyticsData.total_sales.statistic}`"
          :change="analyticsData.total_sales.change"
          stat-title="Total Sales"
          subtitle="Total sales accumulated"
        ></statistics-card-vertical>
      </v-col>

      <v-col
        cols="6"
        md="3"
      >
        <statistics-card-vertical
          color="primary"
          :icon="icons.mdiBookMultipleOutline"
          :statistics="analyticsData.total_quotations.statistic"
          :change="analyticsData.total_quotations.change"
          stat-title="Total Quotes"
          subtitle="Total quotations accumulated"
        ></statistics-card-vertical>
      </v-col>

      <v-col
        cols="6"
        md="3"
      >
        <statistics-card-vertical
          color="primary"
          :icon="icons.mdiReceipt"
          :statistics="`$${analyticsData.average_order.statistic}`"
          :change="analyticsData.average_order.change"
          stat-title="Average order"
          subtitle="Average order price per quote"
        ></statistics-card-vertical>
      </v-col>

      <v-col
        cols="6"
        md="3"
      >
        <statistics-card-vertical
          color="primary"
          :icon="icons.mdiAccountGroupOutline"
          :statistics="analyticsData.total_customers.statistic"
          :change="analyticsData.total_customers.change"
          stat-title="Total Customers"
          subtitle="Total customers accummulated"
        ></statistics-card-vertical>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card>
          <v-card-title class="d-flex align-center flex-wrap pb-0">
            <div
              class="d-flex align-center"
              :class="{ 'pb-5': $vuetify.breakpoint.smAndDown }"
            >
              <span>Sales Report</span>
            </div>

            <v-spacer></v-spacer>

            <div
              class="d-flex align-center"
              :class="{ 'pb-5': $vuetify.breakpoint.smAndDown }"
            >
              <!-- filter view -->
              <v-select
                v-model="viewFilter"
                :items="viewOptions"
                single-line
                outlined
                dense
                hide-details
                :placeholder="t('Select View')"
                :class="{ 'dashboard-filter': $vuetify.breakpoint.smAndUp }"
              ></v-select>
            </div>
          </v-card-title>

          <!-- chart -->
          <v-card-text
            class="mt-0 mt-md-5 chart-wrapper"
            :class="{ 'pl-0': $vuetify.breakpoint.xsOnly }"
          >
            <p
              v-if="$vuetify.breakpoint.xsOnly"
              class="mb-2 pl-7 text-xs"
            >
              BND$ x Month
            </p>
            <bar-chart
              v-if="doneFetching"
              :width="1500"
              :height="400"
              :data="chartData"
              :options="chartjsConfig.options"
            />
            <v-container
              v-else
              fill-height
              fluid
              style="height: 400px;"
            >
              <v-row justify="center">
                <v-col class="text-center">
                  <v-sheet
                    v-if="$vuetify.breakpoint.smAndUp"
                    class="d-flex flex-column justify-space-between align-center"
                  >
                    <v-progress-circular
                      :rotate="360"
                      :size="350"
                      :width="45"
                      :value="progressLoader"
                      color="primary"
                    >
                      <h2>{{ progressLoader }}% data gathering...</h2>
                    </v-progress-circular>
                  </v-sheet>
                  <v-sheet v-else>
                    <h2 class="mb-5">
                      {{ progressLoader }}% data gathering...
                    </h2>
                    <v-progress-linear
                      color="primary"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <!-- Release Widget -->
        <v-card class="px-2 d-none d-sm-block">
          <v-card-title class="d-flex align-center">
            <div class="text-h6 text-no-wrap">
              New Releases ✨
            </div>
          </v-card-title>
          <template v-if="featuredReleaseNote != 0">
            <v-card-subtitle class="font-weight-semibold">
              {{ `${featuredReleaseNote.version} - ${featuredDateFormatted}` }}
            </v-card-subtitle>
            <div
              class="featured-note text-caption mx-5"
              style="max-height: 38vh;"
              v-html="featuredReleaseNote.note"
            ></div>
            <div class="text-center py-5">
              <v-dialog
                v-model="releaseNotesDialog"
                scrollable
                max-width="95vh"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="caption text-decoration-underline primary--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    View more releases
                  </span>
                </template>
                <v-card>
                  <v-card-title class="d-flex align-center">
                    <div class="text-h6 text-no-wrap">
                      New Releases ✨
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      small
                      @click="releaseNotesDialog = false"
                    >
                      <v-icon size="22">
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-tabs
                    v-if="$vuetify.breakpoint.mdAndUp"
                    hide-slider
                    vertical
                  >
                    <v-tab
                      v-for="item in releaseNotes"
                      :key="item.id"
                      class="caption text-decoration-underline text-lowercase"
                    >
                      {{ `${item.version} - ${item.released_at}` }}
                    </v-tab>

                    <v-tab-item
                      v-for="item in releaseNotes"
                      :key="item.id"
                      :transition="false"
                    >
                      <v-card
                        min-height="70vh"
                        flat
                      >
                        <v-card-text>
                          <p v-html="item.note"></p>
                        </v-card-text>
                      </v-card>
                      <div class="text-right mx-5 mb-5">
                        <v-btn
                          depressed
                          color="primary"
                          @click="releaseNotesDialog = false"
                        >
                          Ok, Got It!
                        </v-btn>
                      </div>
                    </v-tab-item>
                  </v-tabs>
                  <template v-else>
                    <v-select
                      v-model="currentReleaseNoteItem"
                      :items="releaseNotes"
                      item-value="note"
                      outlined
                      dense
                      class="mx-5"
                    >
                      <template
                        slot="selection"
                        slot-scope="data"
                      >
                        <div class="caption text-lowercase">
                          {{ data.item.version }} - {{ data.item.released_at }}
                        </div>
                      </template>
                      <template
                        slot="item"
                        slot-scope="data"
                      >
                        <div class="caption text-lowercase">
                          {{ data.item.version }} - {{ data.item.released_at }}
                        </div>
                      </template>
                    </v-select>
                    <div>
                      <v-card
                        min-height="65vh"
                        flat
                      >
                        <v-card-text>
                          <p v-html="currentReleaseNoteItem"></p>
                        </v-card-text>
                      </v-card>
                      <div class="text-right mx-5 mb-5">
                        <v-btn
                          depressed
                          color="primary"
                          @click="releaseNotesDialog = false"
                        >
                          Ok, Got It!
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-card>
              </v-dialog>
            </div>
          </template>
          <v-card-subtitle
            v-else
            class="font-weight-semibold"
          >
            Release note will be added soon.
          </v-card-subtitle>
        </v-card>

        <!-- Release Widget on mobile -->
        <v-expansion-panels class="d-block d-sm-none">
          <v-expansion-panel class="px-2 pb-2">
            <v-expansion-panel-header>
              <div>
                <div class="text-h6 text-no-wrap mb-2">
                  New Releases ✨
                </div>
                <span
                  v-if="featuredReleaseNote != 0"
                  class="font-weight-semibold"
                >
                  {{ `${featuredReleaseNote.version} - ${featuredDateFormatted}` }}
                </span>
                <span
                  v-else
                  class="font-weight-semibold"
                >
                  Release note will be added soon.
                </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="featuredReleaseNote != 0">
              <p
                class="featured-note text-caption"
                v-html="featuredReleaseNote.note"
              ></p>
              <div class="text-center">
                <span
                  class="caption text-decoration-underline primary--text"
                  @click="releaseNotesDialog = true"
                >
                  View more releases
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col
        sm="8"
        cols="12"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="my-auto">
                <div class="text-md-subtitle-2 text-lg-h6">
                  Due In 7 Days ({{ dueDate() }})
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                align="end"
              >
                <v-sheet height="35">
                  <v-btn
                    color="primary"
                    class="me-3"
                    :disabled="selected.length == 0"
                    @click="isReminderDialogVisible = true"
                  >
                    Send all reminder emails
                  </v-btn>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                cols="4"
                lg="2"
                md="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="tablePagination.per"
                    :items="listLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="invoice-list-row-selection"
                    @input="listLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    Rows
                  </div>
                </div>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                lg="10"
                md="9"
                cols="4"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="tablePagination.current_page"
                  total-visible="6"
                  :length="tablePagination.total_page"
                  @input="fetchInvoices"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- table -->
          <v-data-table
            v-model="selected"
            :headers="tableHeaders"
            :items="invoices"
            :options.sync="options"
            :items-per-page="100"
            :loading="loading"
            item-key="serial_number"
            show-select
            mobile-breakpoint="0"
            hide-default-footer
            class="text-no-wrap fixed-action d-none d-sm-block"
          >
            <template v-slot:header.data-table-select="{props,on}">
              <v-simple-checkbox
                :ripple="false"
                color="primary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>

            <template v-slot:item.data-table-select="{isSelected,select}">
              <v-simple-checkbox
                :ripple="false"
                color="primary"
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>

            <!-- Invoice Number -->
            <template #[`item.serial_number`]="{item}">
              <router-link
                class="font-weight-medium text-decoration-none"
                :to="{ name: 'invoice-view', params: { id: item.id } }"
              >
                {{ item.invoice_number }}
              </router-link>
            </template>

            <!-- Status -->
            <template #[`item.status`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    size="30"
                    :color="invoiceStatus(item.status).variant"
                    :class="`v-avatar-light-bg ${invoiceStatus(item.status).variant}--text`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      size="18"
                      :color="invoiceStatus(item.status).variant"
                    >
                      {{ invoiceStatus(item.status).icon }}
                    </v-icon>
                  </v-avatar>
                </template>
                <span>{{ item.status }}</span>
              </v-tooltip>
            </template>

            <!-- Actions -->
            <template #[`item.actions`]="{item}">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="me-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item :to="{ name: 'receipt-form', params: { invoice_id: item.id } }">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                      <span>Create Receipt</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="invoice = item; selected = []; isReminderDialogVisible = true">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSend }}
                      </v-icon>
                      <span>Send Mail</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item :to="{ name: 'invoice-view', params: { id: item.id } }">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                      <span>View</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item :to="{ name: 'invoice-form', params: { id: item.id } }">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                      <span>Edit</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="invoice = item; isDuplicateDialogVisible = true">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                      <span>Duplicate</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="invoice = item; isDownloadDialogVisible = true">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDownload }}
                      </v-icon>
                      <span>Download</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item @click="invoice = item; isVoidDialogVisible = true">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>Void</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>

          <!-- table view for mobile -->
          <v-data-table
            v-model="selected"
            :headers="tableSmHeaders"
            :items="invoices"
            :options.sync="options"
            :items-per-page="100"
            :loading="loading"
            :expanded.sync="expanded"
            single-expand
            show-expand
            item-key="serial_number"
            show-select
            mobile-breakpoint="0"
            hide-default-footer
            class="text-no-wrap fixed-action d-block d-sm-none"
          >
            <!-- Invoice Number -->
            <template #[`item.serial_number`]="{item}">
              <router-link
                class="font-weight-medium text-decoration-none"
                :to="{ name: 'invoice-view', params: { id: item.id } }"
              >
                {{ item.invoice_number }}
              </router-link>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td
                class="table-expand"
                :colspan="headers.length"
              >
                <div class="d-flex">
                  <p class="mt-2">
                    Status:
                  </p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        size="24"
                        :color="invoiceStatus(item.status).variant"
                        :class="`v-avatar-light-bg ${invoiceStatus(item.status).variant}--text my-auto ml-1`"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          size="18"
                          :color="invoiceStatus(item.status).variant"
                        >
                          {{ invoiceStatus(item.status).icon }}
                        </v-icon>
                      </v-avatar>
                    </template>
                  </v-tooltip>
                </div>
                <p>Customer Name: {{ item.customer_name }}</p>
                <div class="d-flex">
                  <p>Amount Due: {{ item.amount_due }}</p>
                  <v-spacer></v-spacer>
                  <div class="fixed-expand-action">
                    <v-menu
                      bottom
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="me-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item :to="{ name: 'receipt-form', params: { invoice_id: item.id } }">
                          <v-list-item-title>
                            <v-icon
                              size="16"
                              class="me-2"
                            >
                              {{ icons.mdiPlus }}
                            </v-icon>
                            <span class="caption">Create Receipt</span>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="invoice = item; selected = []; isReminderDialogVisible = true">
                          <v-list-item-title>
                            <v-icon
                              size="16"
                              class="me-2"
                            >
                              {{ icons.mdiSend }}
                            </v-icon>
                            <span class="caption">Send Mail</span>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item :to="{ name: 'invoice-view', params: { id: item.id } }">
                          <v-list-item-title>
                            <v-icon
                              size="16"
                              class="me-2"
                            >
                              {{ icons.mdiEyeOutline }}
                            </v-icon>
                            <span class="caption">View</span>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item :to="{ name: 'invoice-form', params: { id: item.id } }">
                          <v-list-item-title>
                            <v-icon
                              size="16"
                              class="me-2"
                            >
                              {{ icons.mdiPencilOutline }}
                            </v-icon>
                            <span class="caption">Edit</span>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="invoice = item; isDuplicateDialogVisible = true">
                          <v-list-item-title>
                            <v-icon
                              size="16"
                              class="me-2"
                            >
                              {{ icons.mdiContentCopy }}
                            </v-icon>
                            <span class="caption">Duplicate</span>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="invoice = item; isDownloadDialogVisible = true">
                          <v-list-item-title>
                            <v-icon
                              size="16"
                              class="me-2"
                            >
                              {{ icons.mdiDownload }}
                            </v-icon>
                            <span class="caption">Download</span>
                          </v-list-item-title>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item @click="invoice = item; isVoidDialogVisible = true">
                          <v-list-item-title>
                            <v-icon
                              size="16"
                              class="me-2"
                            >
                              {{ icons.mdiDeleteOutline }}
                            </v-icon>
                            <span class="caption">Void</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </td>
            </template>
          </v-data-table>

          <!-- Reminder dialog -->
          <v-dialog
            v-model="isReminderDialogVisible"
            width="500"
          >
            <v-card>
              <v-card-title class="d-flex align-center mv-4">
                {{ selected.length == 0 ? `Send reminder email for ${invoice.invoice_number}?` : `Send ${selected.length} reminder emails` }}
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="isReminderDialogVisible = false"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text v-if="selected.length == 0">
                Send reminder email to the customer regarding payment on this invoice which will due soon. This invoice will be attached to the mail.
              </v-card-text>

              <v-card-text v-else>
                Send reminder email to customer regarding payment on their invoice which will due soon. Invoices will be attached to their respective mail.
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  block
                  :loading="reminderLoading"
                  :disabled="reminderLoading"
                  class="mt-3"
                  @click="remindInvoice()"
                >
                  Send mail
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Duplicate dialog -->
          <v-dialog
            v-model="isDuplicateDialogVisible"
            width="500"
          >
            <v-card>
              <v-card-title class="d-flex align-center mv-4">
                Duplicate {{ invoice.invoice_number }}?
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="isDuplicateDialogVisible = false"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                This invoice will be duplicated. It copy all data present in this current invoice including line items.
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  block
                  :loading="duplicateLoading"
                  :disabled="duplicateLoading"
                  class="mt-3"
                  @click="duplicateInvoice()"
                >
                  Make another copy
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Download dialog -->
          <v-dialog
            v-model="isDownloadDialogVisible"
            width="500"
          >
            <v-card>
              <v-card-title class="d-flex align-center mv-4">
                Download {{ invoice.invoice_number }}?
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="isDownloadDialogVisible = false"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                Export this invoice as PDF.
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  block
                  :loading="downloadLoading"
                  :disabled="downloadLoading"
                  class="mt-3"
                  @click="downloadInvoice()"
                >
                  Download PDF
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Void dialog -->
          <v-dialog
            v-model="isVoidDialogVisible"
            width="500"
          >
            <v-card>
              <v-card-title class="d-flex align-center mv-4">
                Void {{ invoice.invoice_number }}?
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="isVoidDialogVisible = false"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                This invoice will be voided. It won't be taken into any calculations.
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="error"
                  block
                  :loading="voidLoading"
                  :disabled="voidLoading"
                  class="mt-3"
                  @click="voidInvoice()"
                >
                  Remove
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                cols="4"
                lg="2"
                md="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="tablePagination.per"
                    :items="listLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="invoice-list-row-selection"
                    @input="listLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    Rows
                  </div>
                </div>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                lg="10"
                md="9"
                cols="4"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="tablePagination.current_page"
                  total-visible="6"
                  :length="tablePagination.total_page"
                  @input="fetchInvoices"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-card class="text-center">
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar
              color="primary"
              icon
              size="60"
              class="elevation-3"
            >
              <v-icon
                size="35"
                color="white"
                class="rounded-0"
              >
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </v-avatar>
            <h6 class="text-xl mt-4 font-weight-medium">
              Contact Support
            </h6>
          </v-card-text>

          <v-card-text>
            Found an issue you would like to report? Contact our support team here
          </v-card-text>

          <v-card-text>
            <v-btn
              block
              color="primary"
              class="mt-4"
              target="_blank"
              href="mailto:support@clericle.com?subject=I want to report an issue"
            >
              Contact Now
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiOpenInNew,
  mdiLockOpenOutline,
  mdiViewListOutline,
  mdiStarOutline,
  mdiUpdate,
  mdiHelpCircleOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiContentCopy,
  mdiDownload,
  mdiDeleteOutline,
  mdiChartTimelineVariant,
  mdiAlertCircleOutline,
  mdiClose,
  mdiCurrencyUsd,
  mdiBookMultipleOutline,
  mdiReceipt,
  mdiAccountGroupOutline,
  mdiSend,
} from '@mdi/js'
import {
  inject,
  ref,
  onMounted,
  watch,
  computed,
} from '@vue/composition-api'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
import BarChart from '@/components/charts/BarChart.vue'
import { saveAs } from 'file-saver'
import chartjsConfig from './chartjsConfig'

export default {
  components: {
    StatisticsCardVertical,
    BarChart,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const t = inject('t')

    // Properties
    const doneFetching = ref(false)
    const chartData = ref({})
    const analyticsData = ref(null)
    const viewFilter = ref('12 Months')
    const viewOptions = ref(['6 Months', '12 Months', '24 Months'])
    const progressInterval = ref({})
    const progressLoader = ref(0)

    const featuredReleaseNote = ref({})
    const releaseNotes = ref([])
    const currentReleaseNoteItem = ref({})
    const releaseNotesDialog = ref(false)

    const invoices = ref([])
    const invoice = ref({})

    const isVoidDialogVisible = ref(false)
    const voidLoading = ref(false)
    const isDuplicateDialogVisible = ref(false)
    const duplicateLoading = ref(false)
    const isDownloadDialogVisible = ref(false)
    const downloadLoading = ref(false)
    const isReminderDialogVisible = ref(false)
    const reminderLoading = ref(false)

    // Table Handlers
    const loading = ref(false)
    const selected = ref([])
    const expanded = ref([])
    const options = ref({
      sortBy: ['serial_number'],
      sortDesc: [true],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'INVOICE NO.', value: 'serial_number' },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'CUSTOMER NAME', value: 'customer_name' },
        { text: 'DUE DATE', value: 'due_date' },
        { text: 'AMOUNT DUE', value: 'amount_due' },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    ))
    const tableSmHeaders = computed(() => (
      [
        { text: 'INVOICE NO.', value: 'serial_number' },
        { text: 'DUE DATE', value: 'due_date' },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Computed
    const featuredDateFormatted = computed(() => utilsService.formatDate(featuredReleaseNote.value.released_at))

    // Methods
    const sanitizeChartData = data => {
      data.datasets.forEach((dataset, index) => {
        if (dataset.type === 'line') {
          dataset.fill = false
          dataset.borderColor = chartjsConfig.chartColors[index]
          dataset.pointBackgroundColor = chartjsConfig.chartColors[index]
          dataset.tension = 0
          dataset.order = 1
        }

        if (dataset.type === 'bar') {
          dataset.backgroundColor = chartjsConfig.chartColors[index]
          dataset.borderColor = 'transparent'
          dataset.order = 2
        }
      })

      return data
    }
    const fetchAnalytics = () => {
      progressInterval.value = setInterval(() => {
        if (progressLoader.value === 100) progressLoader.value = 0
        progressLoader.value += 1
      }, 500)
      doneFetching.value = false
      store
        .dispatch('dashboardStore/fetchAnalytics', { view: viewFilter.value.split(' ')[0] })
        .then(response => {
          analyticsData.value = response.data.data
          chartData.value = sanitizeChartData(analyticsData.value.graph_data)
          clearInterval(progressInterval.value)
          progressLoader.value = 0
          doneFetching.value = true
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while generating analytics report. Please refresh!')
        })
    }
    const fetchReleaseNotes = () => {
      store
        .dispatch('releaseNoteStore/fetchReleaseNotes', { sort_by: 'version', sort_dir: 'desc' })
        .then(response => {
          releaseNotes.value = response.data.data
          featuredReleaseNote.value = response.data.featured
          currentReleaseNoteItem.value = featuredReleaseNote.value.note
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching release notes. Please refresh!')
        })
    }
    const fetchInvoices = () => {
      loading.value = true
      store
        .dispatch('invoiceStore/fetchInvoices', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          due_this_week: true,
        })
        .then(response => {
          const { data, pagination } = response.data
          invoices.value = data
          tablePagination.value = pagination
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching invoices. Please refresh!')
        })
    }
    const duplicateInvoice = () => {
      duplicateLoading.value = true
      store
        .dispatch('invoiceStore/duplicateInvoice', { id: invoice.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchInvoices()
          isDuplicateDialogVisible.value = false
          duplicateLoading.value = false
        })
        .catch(error => {
          duplicateLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while duplicating invoice. Please refresh!')
        })
    }
    const downloadInvoice = () => {
      downloadLoading.value = true
      store
        .dispatch('invoiceStore/downloadInvoice', { id: invoice.value.id })
        .then(response => {
          saveAs(response, `${invoice.value.invoice_number}.pdf`)
          isDownloadDialogVisible.value = false
          downloadLoading.value = false
        })
        .catch(error => {
          downloadLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while downloading invoice. Please refresh!')
        })
    }
    const remindInvoice = () => {
      reminderLoading.value = true

      const invoiceIds = []
      if (selected.value.length > 0) {
        selected.value.forEach(item => {
          invoiceIds.push(item.id)
        })
      } else {
        invoiceIds.push(invoice.value.id)
      }
      store
        .dispatch('invoiceStore/remindInvoice', { invoice_ids: invoiceIds })
        .then(response => {
          snackbarService.success(response.data.message)
          isReminderDialogVisible.value = false
          reminderLoading.value = false
        })
        .catch(error => {
          reminderLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending email reminder. Please refresh!')
        })
    }
    const voidInvoice = () => {
      voidLoading.value = true
      store
        .dispatch('invoiceStore/voidInvoice', { id: invoice.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchInvoices()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding invoice. Please refresh!')
        })
    }
    const invoiceStatus = status => {
      let variantAndIcon = {}
      switch (status) {
        case 'Partially Paid':
          variantAndIcon = { variant: 'warning', icon: mdiChartTimelineVariant }
          break
        case 'Outstanding':
          variantAndIcon = { variant: 'error', icon: mdiAlertCircleOutline }
          break
        default:
          variantAndIcon = { variant: 'secondary', icon: mdiClose }
      }

      return variantAndIcon
    }
    const dueDate = () => {
      const date1 = new Date()
      const date2 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + 6)

      return `${date1.getDate()}/${date1.getMonth() + 1}/${date1.getFullYear()} - ${date2.getDate()}/${date2.getMonth() + 1}/${date2.getFullYear()}`
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchInvoices()
    }
    const checkValue = value => value && value.charAt(0) !== '-'

    // Watch
    watch([viewFilter], () => fetchAnalytics())

    // Mounted
    onMounted(async () => fetchAnalytics(), fetchInvoices(), fetchReleaseNotes())

    return {
      // Initializers
      t,

      // Computed
      featuredDateFormatted,

      // Properties
      chartjsConfig,
      doneFetching,
      analyticsData,
      chartData,
      viewFilter,
      viewOptions,
      progressLoader,

      featuredReleaseNote,
      releaseNotes,
      currentReleaseNoteItem,
      releaseNotesDialog,

      invoice,
      invoices,

      isVoidDialogVisible,
      voidLoading,
      isDuplicateDialogVisible,
      duplicateLoading,
      isDownloadDialogVisible,
      downloadLoading,
      isReminderDialogVisible,
      reminderLoading,

      // Table Handlers
      loading,
      selected,
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,
      tableSmHeaders,

      // Icons
      icons: {
        mdiCalendar,
        mdiOpenInNew,
        mdiLockOpenOutline,
        mdiViewListOutline,
        mdiStarOutline,
        mdiUpdate,
        mdiHelpCircleOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiContentCopy,
        mdiDownload,
        mdiDeleteOutline,
        mdiChartTimelineVariant,
        mdiAlertCircleOutline,
        mdiClose,
        mdiCurrencyUsd,
        mdiBookMultipleOutline,
        mdiReceipt,
        mdiAccountGroupOutline,
        mdiSend,
      },

      // Methods
      fetchInvoices,
      duplicateInvoice,
      downloadInvoice,
      remindInvoice,
      voidInvoice,
      invoiceStatus,
      dueDate,
      listLengthChange,
      checkValue,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

  .dashboard-filter {
    max-width: 12.2rem;
  }

  .chart-wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  @include theme--child(memberpricing-bg) using ($material) {
    background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
  }

  .memberpricing-bg {
    position: relative;
  }

  .membership-pricing {
    text-align: center;
    sup {
      font-size: 3.75rem;
      top: 9px;
    }
  }
</style>

<style>
.featured-note {
  overflow: hidden;
}
</style>
