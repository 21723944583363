import themeConfig from '@themeConfig'

const $themeColors = themeConfig.themes.light
const iconColors = [
  $themeColors.info,
  $themeColors.success,
  $themeColors.warning,
  $themeColors.error,
]
const chartColors = [
  'rgba(1, 146, 103)',
  'rgba(247, 212, 118)',
  'rgba(75, 192, 192)',
  'rgba(255, 159, 64)',
  'rgba(54, 162, 235)',
  'rgba(255, 205, 86)',
  'rgba(153, 102, 255)',

]
const configColors = {
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  iconColors,
  chartColors,
  options: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        padding: 30,
        usePointStyle: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 500,
    tooltips: {
      shadowOffsetX: 1,
      shadowOffsetY: 1,
      shadowBlur: 8,
      shadowColor: configColors.tooltipShadow,
      callbacks: {
        label: tooltipItem => (`BND$${tooltipItem.yLabel.toFixed(2)}`),
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            color: configColors.grid_line_color,
            zeroLineColor: configColors.grid_line_color,
          },
          scaleLabel: {
            display: true,
            labelString: window.innerWidth <= 425 ? '' : 'Months',
          },
          ticks: {
            fontColor: configColors.labelColor,
            callback: label => {
              if (window.innerWidth <= 425) return `${label[0]}`

              return `${label.split(' ')[0]} '${label.split(' ')[1].slice(-2)}`
            },
          },
          afterFit: scale => {
            scale.height = window.innerWidth <= 425 ? 10 : 65
          },
        },
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            color: configColors.grid_line_color,
            zeroLineColor: configColors.grid_line_color,
          },
          scaleLabel: {
            display: true,
            labelString: window.innerWidth <= 425 ? '' : 'BND$',
          },
          ticks: {
            fontColor: configColors.labelColor,
            callback: label => {
              if ((label >= 1) && (label < 10)) return `${label}.00`
              if (label > 1000) return `${label / 1000}k`

              return label
            },
          },
        },
      ],
    },
  },
}
